import { mapIcons } from '@/utils/FileTypeChecker';

const getIconByType = toFind => {
  const found = mapIcons.find(({ type }) => type.includes(toFind));

  return found?.icon ?? ['fal', 'camera-retro'];
};

const CAROUSEL_TYPE = ['carousel', 'carousel_image', 'carousel_video', 'album'];
const VIDEO_TYPE = ['video', 'video_inline', 'video_direct_response'];
const PHOTO_TYPE = ['image', 'photo', 'cover_photo'];

export default {
  methods: {
    typeIcon(type) {
      if (!type) {
        return ['fal', 'question-circle'];
      }

      if (CAROUSEL_TYPE.includes(type)) {
        return ['fal', 'clone'];
      }

      if (PHOTO_TYPE.includes(type)) {
        return ['fal', 'camera-retro'];
      }

      if (VIDEO_TYPE.includes(type)) {
        return ['fal', 'video'];
      }

      return getIconByType(type);
    },
  },
};
