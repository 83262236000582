// @flow

import type { File } from '../../types';

import Bootstrap from '../lib/Bootstrap';

const allowedFormats = Bootstrap.getBootstrapData('AllowedFormats');
const bootstrapData = Bootstrap.getBootstrapData('MediaAcceptParam');

export const mapIcons = [
  {
    type: 'image/*',
    icon: ['fal', 'camera-retro'],
    color: '#2c3e50',
  },
  {
    type: 'video/*',
    icon: ['fal', 'video'],
    color: '#40407a',
  },
  {
    type: 'audio/*',
    icon: ['fal', 'volume-up'],
    color: '#0984e3',
  },
  {
    type: 'application/*pdf*',
    icon: ['fal', 'file-pdf'],
    color: '#ff5252',
  },
  {
    type:
      'application/*postscript*|application/*illustrator*|application/*pdf*',
    icon: ['fal', 'bezier-curve'],
    color: '#f77800',
  },
  {
    type:
      'text/plain|application/*opendocument.text*|application/*xml.writer*|application/onenote',
    icon: ['fal', 'sticky-note'],
    color: '#fdcb6e',
  },
  {
    type: 'application/*msword*|application/*ms-word*',
    icon: ['fal', 'file-word'],
    color: '#34ace0',
  },
  {
    type: 'application/*excel*|document.spreadsheet*',
    icon: ['fal', 'file-excel'],
    color: '#33d9b2',
  },
  {
    type: 'application/*mspowerpoint*|application/*ms-powerpoint*',
    icon: ['fal', 'file-power-point'],
    color: '#ff793f',
  },
  {
    type: 'application/*zip*|application/x-rar-compressed',
    icon: ['fal', 'file-archive'],
    color: '#f5cd79',
  },
];

export default {
  allowedTypes(mediaAcceptParam) {
    const arrayAllowed = ['image', 'video', 'audio', 'text', 'application'];

    if (!mediaAcceptParam) {
      return arrayAllowed.map(type => {
        return type === 'application' || type === 'text'
          ? 'assets'
          : `${type}s`;
      });
    }

    return arrayAllowed
      .filter(type => {
        if (mediaAcceptParam.match(`${type}/*`)) {
          return type;
        }
      })
      .map(type => {
        return type === 'application' || type === 'text'
          ? 'assets'
          : `${type}s`;
      });
  },

  allowedExtensions(mimetype) {
    const mime = mimetype.split('/')[0];

    const allowed =
      mime === 'application' || mime === 'text' ? 'assets' : `${mime}s`;
    const regExp = new RegExp(`${mime}/`, 'g');

    return allowedFormats['pdf'].includes(mimetype)
      ? mimetype.replace(regExp, '').split()
      : bootstrapData[`${allowed}`].replace(regExp, '').split(',');
  },

  isAudio(file: File): boolean {
    const { mimetype, ext } = file || {};

    return (mimetype && mimetype.includes('audio')) || ext === 'ogg';
  },

  isDocument(file: File): boolean {
    return (
      !this.isAudio(file) &&
      !this.isVideo(file) &&
      !this.isImage(file) &&
      !this.isPdf(file)
    );
  },

  isImage(file): boolean {
    const mime = file.mime ? file.mime : file.mimetype;
    return bootstrapData.images.includes(mime);
  },

  isOfficeDocument(image): boolean {
    const officeDocumentsWithPreview = ['ppt', 'word', 'excel'];
    const { mimetype, ext } = image;

    const allowedExtensions = this.getMediaTypes()
      .filter(type => officeDocumentsWithPreview.includes(type.key))
      .reduce((extensions, type) => {
        return [...extensions, ...type.exts];
      }, []);

    const allowedMimes = allowedExtensions.reduce((mimes, ext) => {
      return [...mimes, ...allowedFormats[ext]];
    }, []);

    return allowedExtensions.includes(ext) && allowedMimes.includes(mimetype);
  },

  isAdobe(file): boolean {
    const { mimetype, name } = file || {};
    const match =
      allowedFormats['ai'].includes(mimetype) ||
      allowedFormats['psd'].includes(mimetype);
    const extension = name ? name.substr(name.lastIndexOf('.') + 1) : '';

    return match
      ? !(extension === 'pdf')
      : extension === 'ai' || extension === 'psd';
  },

  isPdf(file): boolean {
    const { mimetype, name } = file || {};
    const match = allowedFormats['pdf'].includes(mimetype);
    const extension = name ? name.substr(name.lastIndexOf('.') + 1) : '';

    return match ? !(extension === 'ai') : extension === 'pdf';
  },

  isText(file: File): boolean {
    const { mimetype } = file || {};

    return file.ext === 'txt' && allowedFormats['txt'].includes(mimetype);
  },

  isVideo(file: File): boolean {
    const mime = file.mime ? file.mime : file.mimetype;

    return bootstrapData.videos.includes(mime);
  },

  getFileType(file) {
    if (!file) {
      return null;
    }

    //The type check is ordered by frecuency of use
    if (this.isImage(file)) {
      return 'image';
    }

    if (this.isPdf(file)) {
      return 'pdf';
    }

    if (this.isVideo(file)) {
      return 'video';
    }

    if (this.isAudio(file)) {
      return 'audio';
    }

    return 'document';
  },

  getIconByMimeType(mimetype) {
    const icons = mapIcons;

    if (mimetype) {
      const finder = icons.find(item => {
        if (mimetype.match(item.type)) {
          return item;
        }
      });

      if (finder) {
        return finder;
      }
    }

    return {
      icon: ['fal', 'file-exclamation'],
      color: '#34495e',
    };
  },

  getMediaTypes(): Array {
    return [
      {
        label: 'Audio',
        key: 'audio',
        exts: ['mp3', 'ogg', 'wav', 'wma'],
      },
      {
        label: 'Excel',
        key: 'excel',
        exts: ['xls', 'xlsx'],
      },
      {
        label: 'Image',
        key: 'image',
        exts: ['gif', 'jpe', 'jpeg', 'jpg', 'png'],
      },
      {
        alias: ['illustrator', 'design'],
        label: 'Ai',
        key: 'ai',
        exts: ['ai'],
      },
      {
        label: 'PDF',
        key: 'pdf',
        exts: ['pdf'],
      },
      {
        alias: ['slides', 'presentation', 'powerpoint'],
        label: 'PPT',
        key: 'ppt',
        exts: ['ppt', 'pptx'],
      },
      {
        alias: ['photoshop', 'design'],
        label: 'PSD',
        key: 'psd',
        exts: ['psd', 'psb', 'tiff'],
      },
      {
        label: 'Video',
        key: 'video',
        exts: ['avi', 'flv', 'm4v', 'mov', 'mp4', 'webm', 'wmv'],
      },
      {
        label: 'Word',
        key: 'word',
        exts: ['doc', 'docx'],
      },
      {
        alias: ['compressed', 'zip'],
        label: 'Zip',
        key: 'zip',
        exts: ['zip', 'rar'],
      },
    ];
  },

  getMediaTypebyExt(extension): string {
    const mediaTypes = this.getMediaTypes();
    const data = mediaTypes.find(media => media.exts.includes(extension));

    return data.key;
  },

  getLimitSize(file) {
    if (!bootstrapData.all.includes(file.mimetype)) {
      return 0;
    }

    const type = this.getFileType(file);

    if (['document', 'audio', 'pdf', 'audio', 'video'].includes(type)) {
      return 512 * 1024 * 1024;
    }

    if (['image'].includes(type)) {
      return 5 * 1024 * 1024;
    }

    return 0;
  },
};
