import AnalyticsPostCardFooter from '@/components/AnalyticsPostCardFooter';
import ImageWithIconType from '@/components/ImageWithIconType';
import MediaTypeIcon from '@/mixins/MediaTypeIcon';

export default {
  name: 'AnalyticsPostCard',

  mixins: [MediaTypeIcon],

  components: {
    AnalyticsPostCardFooter,
    ImageWithIconType,
  },

  props: {
    aspect: {
      default: '4:4',
    },
    dataToShow: {
      default: () => {
        return [
          'engagementRate',
          'peopleReached',
          'publishDate',
          'totalClicks',
          'totalEngagements',
          'shares',
          'comments',
        ];
      },
      type: Array,
    },
    post: {
      required: true,
      type: Object,
    },
    thumbnail: {
      default: null,
    },
    src: {
      default: undefined,
    },
    type: {
      default: 'photo',
    },
    itemTextClass: {
      required: false,
      default: null,
      type: String,
    },
    itemIconClass: {
      required: false,
      default: null,
      type: String,
    },
  },

  data() {
    return {
      generatedThumbnail: null,
    };
  },

  computed: {
    aspectImage() {
      return this.post?.type === 'TikTokPost' ? '9:16' : this.aspect;
    },
    metrics() {
      return {
        ...(this.post ?? {}),
        ...(this.post?.metrics ?? {}),
      };
    },

    //Src is a video url (we need generate a thumbnail)
    isVideo() {
      return (
        ['video', 'carousel_video'].includes(this.type) &&
        (this.src || '').indexOf('.mp4') != -1
      );
    },

    urlImage() {
      if (this.isVideo || this.post.__typename === 'RemoteYoutubeVideo') {
        return this.generatedThumbnail;
      }

      return this.src ?? this.baseUrl('img/placeholder_default.png');
    },
  },

  methods: {
    //Generate a thumbnail from a video file
    async getThumbnail(src) {
      const width = 360;
      const height = 360;
      const video = document.createElement('video');

      video.width = width;
      video.height = height;
      video.autoplay = false;
      video.controls = false;
      video.muted = 'muted';
      video.currentTime = 0.2;
      video.style.width = width;
      video.style.height = height;

      const url = await fetch(src, { headers: { range: 'bytes=0-256000' } })
        .then(response => response.arrayBuffer(32))
        .then(buffer => {
          return URL.createObjectURL(new Blob([buffer], { type: 'video/mp4' }));
        });

      video.src = url;

      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      canvas.crossOrigin = 'anonymous';

      video.onloadeddata = () => {
        setTimeout(() => {
          canvas.getContext('2d').drawImage(video, 0, 0, width, height);

          const dataURI =
            canvas.toDataURL('image/jpeg') ||
            this.baseUrl('img/Sharelov_NewPostTypes_Movie.svg');

          this.generatedThumbnail = dataURI;
        }, 100);
      };
    },
  },

  //Async await is required.
  async mounted() {
    if (this.isVideo) {
      await this.getThumbnail(this.src);
    } else if (this.post.__typename === 'RemoteYoutubeVideo') {
      this.generatedThumbnail = this.thumbnail;
    }
  },
};
